import { useController } from "react-hook-form"
import { useEffect, useMemo, useState } from "react"
import { useRouter } from "next/router"

function onKeyDown(e, value) {
  switch (e.key) {
    case " ":
    case "Enter":
      e.preventDefault()
      handleCheckboxChange(value)
      break
  }
}

export function useFacetBox({ control, setValue, facets }) {
  const router = useRouter()
  const { field } = useController({
    name: "facets",
    control,
  })

  // when language changes, reset facets
  useEffect(() => {
    setSelectedFacets([])
    setValue("facets", [])
  }, [setValue, router.locale])

  const [selectedFacets, setSelectedFacets] = useState(
    field.value && typeof field.value === "string" ? field.value.split("|") : []
  )

  function handleCheckboxChange(value) {
    setSelectedFacets((prevFacets) => {
      const returnFacets = new Set(prevFacets)
      if (returnFacets.has(value)) {
        returnFacets.delete(value)
      } else {
        returnFacets.add(value)
      }
      return [...returnFacets]
    })
  }

  useEffect(() => {
    if (selectedFacets) {
      setValue("facets", selectedFacets.join("|"))
    }
  }, [setValue, selectedFacets])

  return {
    setSelectedFacets,
    FacetBox: useMemo(() => {
      return facets ? (
        <fieldset className="mt-4 md:mt-0 bg-gray-100 md:bg-white">
          <ul className="grid-container flex flex-row flex-wrap py-6 md:pt-1 md:pb-3 overflow-x-scroll">
            {facets.map(({ value, count }, index) => {
              const label = `${value} (${count})`
              const selected = selectedFacets?.indexOf(value) > -1
              return (
                <li key={label} className="mb-2 md:mb-0 mr-2">
                  <label
                    tabIndex={0}
                    htmlFor={`facets.${index}`}
                    onClick={() => {
                      handleCheckboxChange(value)
                    }}
                    onKeyDown={(e) => {
                      onKeyDown(e, value)
                    }}
                    className={`select-none usa-button text-sm py-2 px-3 ${
                      selected ? "" : "usa-button--outline bg-white"
                    } whitespace-pre truncate overflow-ellipses max-w-xxs sm:max-w-xs md:max-w-none`}
                  >
                    {label}
                    <input
                      tabIndex={-1}
                      className="sr-only-fixed text-sm"
                      style={{ position: "fixed" }}
                      type="checkbox"
                      value={value}
                      checked={selected}
                      onChange={() => handleCheckboxChange(value)}
                      id={`facets.${index}`}
                    />
                  </label>
                </li>
              )
            })}
          </ul>
          <label htmlFor="facets" className="sr-only">
            Selected Facets
          </label>
          <input
            id="facets"
            className="usa-input sr-only"
            type="text"
            {...field}
          />
        </fieldset>
      ) : null
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facets, selectedFacets]),
  }
}

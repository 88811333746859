export function SkeletonBox({
  h = 4,
  w,
  color = "gray-200",
  tw = "",
  ...props
}) {
  return (
    <div
      className={`h-${h} ${w ? `w-${w}` : ""} bg-${color} rounded ${tw}`}
      {...props}
    />
  )
}

export function JobPreviewCardSkeleton(props) {
  return (
    <div
      className="flex flex-1 flex-row border-2 border-gray-300 rounded-md	p-5 space-x-6 justify-between w-full"
      {...props}
    >
      <div className="flex flex-col w-2/3 justify-between space-y-2 animate-pulse">
        <SkeletonBox h={5} color="gray-300" />
        <div className="flex flex-col space-y-2">
          <SkeletonBox h={3} w={"4/12"} />
          <SkeletonBox h={3} w={"3/12"} />
          <SkeletonBox h={3} w={"5/12"} />
        </div>
      </div>
      <div className="flex flex-col w-1/3 space-y-2 animate-pulse">
        <SkeletonBox h={3} />
        <SkeletonBox h={3} w={"10/12"} />
      </div>
    </div>
  )
}

export function JobPreviewCardsSkeleton() {
  return (
    <div
      className="flex flex-1 flex-col space-y-2 md:max-w-md overflow-y-scroll overflow-visible"
      style={{ maxHeight: "38rem" }}
    >
      {[1, 2, 3, 4].map((n) => (
        <JobPreviewCardSkeleton key={n} />
      ))}
    </div>
  )
}

export function JobDetailSkeleton({ card, classNameOverride }) {
  return (
    <div
      className={
        card
          ? `transition-all md:flex h-full md:flex-1 border-2 border-gray-300 rounded-md space-x-2 justify-between overflow-y-scroll px-6 py-8 ${classNameOverride}`
          : `transition-all grid-container h-full pt-10 ${classNameOverride}`
      }
      style={card ? { maxHeight: "38rem", minHeight: "38rem" } : null}
    >
      <div className="animate-pulse w-full">
        <div className="space-y-4">
          <SkeletonBox h={8} w={"3/4"} color="gray-300" />
          <SkeletonBox h={1} />

          <div className="flex flex-row my-4 w-full">
            <div className="flex flex-col flex-1 justify-between">
              <div className="flex flex-col space-y-3 mb-8">
                <SkeletonBox h={5} w={"3/4"} />
                <SkeletonBox h={5} w={"7/12"} />
              </div>

              <SkeletonBox h={5} w={"1/4"} />
            </div>
            <div className="flex flex-col flex-1 text-right justify-between">
              <div className="flex flex-col space-y-3">
                <SkeletonBox h={5} w={"8/12"} tw="ml-auto" />
                <SkeletonBox h={5} w={"3/4"} tw="ml-auto" />
              </div>

              <SkeletonBox w={"8/12"} tw="ml-auto" />
            </div>
          </div>

          <SkeletonBox h={0.5} />

          <SkeletonBox w={"7/12"} color="gray-300" />

          <div className="flex flex-col md:flex-row">
            <div className="w-64 flex-none">
              <SkeletonBox w={"7/12"} />
            </div>
            <SkeletonBox w={"1/2"} />
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="w-64 flex-none">
              <SkeletonBox w={"3/12"} />
            </div>
            <SkeletonBox w={"2/3"} />
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="w-64 flex-none">
              <SkeletonBox w={"3/4"} />
            </div>
            <SkeletonBox w={"1/12"} />
          </div>
        </div>
      </div>
    </div>
  )
}

import { ErrorMessage } from "@hookform/error-message"
import { getTitleCase } from "utils/cleaners"

export function Input({ register, name, label, errors, ...rest }) {
  const isError = errors?.[name]
  return (
    <fieldset
      className={`usa-form-group ${isError ? "usa-form-group--error" : ""}`}
    >
      <label
        htmlFor={name}
        className={`usa-label ${isError ? "usa-label--error" : ""}`}
      >
        {label || getTitleCase(name)}
      </label>
      <ErrorMessage
        id={`${name}-error`}
        as="span"
        className="usa-error-message"
        errors={errors}
        name={name}
      />
      <input
        id={name}
        aria-invalid={errors?.name ? true : false}
        aria-describedby={errors?.name ? `${name}-error` : null}
        className={`usa-input ${isError ? "usa-input--error" : ""} max-w-none`}
        {...register(name)}
        {...rest}
      />
    </fieldset>
  )
}

export function Textarea({ register, name, label, errors, ...rest }) {
  const isError = errors?.[name]

  return (
    <fieldset
      className={`usa-form-group ${isError ? "usa-form-group--error" : ""}`}
    >
      <label
        htmlFor={name}
        className={`usa-label ${isError ? "usa-label--error" : ""}`}
      >
        {label || getTitleCase(name)}
      </label>

      <ErrorMessage
        id={`${name}-error`}
        as="span"
        className="usa-error-message"
        errors={errors}
        name={name}
      />

      <textarea
        id={name}
        className={`usa-textarea ${
          isError ? "usa-input--error" : ""
        } max-w-none`}
        aria-invalid={errors?.name ? true : false}
        aria-describedby={errors?.name ? `${name}-error` : null}
        {...register(name)}
        {...rest}
      />
    </fieldset>
  )
}

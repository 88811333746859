import { domain } from "env"

// FUNCTION WILL POST AN OFLC LISTING'S DATABASE.
// CLICKS ARE ASSOCIATED WITH THE JOB LISTING CASE NUMBER.
// EVENT TYPE CORRESPONDS WITH WHETHER THE CLICK
// IS ON THE OFLC LISTING CARD (THE DIV
// ON THE LEFT SIDE OF THE LISTINGS PAGE)
// OR A DIRECT LINK FROM REFERER WEBSITE
export async function postPopularity({ case_number, event_type }) {
  try {
    const res = await fetch(`${domain}/personalization/visit/create`, {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        case_number,
        event_type,
      }),
    })
    return await res.json()
  } catch (e) {
    throw new Error(`Could not post to popularity endpoint: ${e}`)
  }
}

import { useRouter } from "next/router"
import { useEffect } from "react"
import { getAzureLocale } from "utils/getAzureLocale"

// Worksite Map
export function JobDetailMap({ coords, atlasRef }) {
  const router = useRouter()

  useEffect(() => {
    let whichAtlas = atlasRef

    if (typeof window !== "undefined") {
      if (!atlasRef) {
        whichAtlas = window.atlas
      }
    }

    const language = getAzureLocale(router.locale)

    if (typeof whichAtlas !== "undefined" && whichAtlas) {
      const map = new whichAtlas.Map("worksiteMap", {
        center: coords,
        zoom: 12,
        language: language,
        style: "grayscale_light",
        renderWorldCopies: false,
        showFeedbackLink: false,
        authOptions: {
          authType: "subscriptionKey",
          subscriptionKey: "OEDzPHdEzLUCrDiMClKbwb-YasE3hez9bbZswBMKwu0",
        },
      })

      map.controls.add(new whichAtlas.control.ZoomControl(), {
        position: "bottom-right",
      })

      map.events.add("ready", function () {
        const dataSource = new whichAtlas.source.DataSource()
        map.sources.add(dataSource)
        const point = new whichAtlas.Shape(new whichAtlas.data.Point(coords))
        //Add the symbol to the data source.
        dataSource.add([point])

        //Create a symbol layer using the data source and add it to the map
        map.layers.add(
          new whichAtlas.layer.SymbolLayer(dataSource, null, {
            iconOptions: {
              image: "marker-red",
              size: 1.1,
              offset: [0, 0],
              opacity: 0.9,
            },
          })
        )
      })
    }
  }, [router.locale, coords, atlasRef])

  return (
    <div
      id="worksiteMap"
      className="w-full h-60 border-2 border-gray-200 my-3"
    />
  )
}

import { LocationIcon } from "assets/LocationIcon"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setUserLocation } from "state"
import { useTranslations } from "use-intl"
import { fetchLocationFromCoordinates } from "utils"

export function CurrentLocation({ setValue, focusInput, setListboxOpen }) {
  const t = useTranslations("Shared")
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { locationName } = useSelector((state) => state.user)
  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }

  async function success(pos) {
    const coords = pos.coords
    const ln = await fetchLocationFromCoordinates(coords)
    dispatch(
      setUserLocation({
        coordinates: {
          latitude: coords.latitude,
          longitude: coords.longitude,
        },
        locationName: ln,
      })
    )
    setValue("location", ln)
    setLoading(false)
  }

  function error(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`)
    setLoading(false)
  }

  const handleOnClickOrSelect = async () => {
    setLoading(true)
    if (!locationName && typeof navigator !== "undefined" && navigator) {
      await navigator.geolocation.getCurrentPosition(success, error, options)
    } else if (locationName) {
      setValue("location", locationName)
      setLoading(false)
      setListboxOpen(false)
      focusInput()
    }
  }

  const handleOnKeyDown = async (e) => {
    switch (e.key) {
      case " ":
      case "Enter":
        e.stopPropagation()
        e.preventDefault()
        handleOnClickOrSelect()
        break
    }
  }

  const renderLoadingLabel = () => {
    if (loading) {
      return t("loading")
    } else if (locationName) {
      return `${t("current_location")}: ${locationName}`
    } else return t("current_location")
  }

  return (
    <li
      id={`autocomplete--location-0`}
      role="option"
      tabIndex="-1"
      aria-selected={false}
      data-option-value={locationName || -1}
      className="px-2 py-1.5 hover:bg-gray-200 focus:bg-gray-200 cursor-pointer text-dolBlue flex flex-row h-9 space-x-2 truncate"
      onClick={handleOnClickOrSelect}
      onKeyDown={handleOnKeyDown}
    >
      <LocationIcon />
      {renderLoadingLabel()}
    </li>
  )
}

import { createRef } from "react"
import { useRouter } from "next/router"
import ReCAPTCHA from "react-google-recaptcha"
import { RECAPTCHA_SITE_KEY } from "env"

export default function Recaptcha({ onRecaptchaChange }) {
  const router = useRouter()

  const recaptchaRef = createRef()

  return (
    <div>
      <ReCAPTCHA
        className="mt-7"
        ref={recaptchaRef}
        key={router.locale}
        hl={router.locale}
        // Needs to be replaced with an actual key on local development
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={onRecaptchaChange}
      />
    </div>
  )
}

import { useTranslations } from "use-intl"
import { Select, DateInput } from "components/Form"
import { useDispatch } from "react-redux"
import { setJobsSlice } from "state"

export function FilterBox({
  which,
  register,
  reset,
  setValue,
  control,
  enableRadius,
  classNameOverride,
  setSelectedFacets,
}) {
  const dispatch = useDispatch()
  const t = useTranslations("JobSearch.Filters")
  const isJobsPage = which === "jobs"
  const isAdvancedSearchPage = which === "archive"
  const columnSpan = isJobsPage ? "col-span-3" : "col-span-4"
  return (
    <div className={`my-6 bg-gray-100 md:border-gray-300 ${classNameOverride}`}>
      <div className="grid-container py-6">
        <div className="flex flex-col md:flex-row 4 justify-between">
          <h2 className="text-md font-bold font-serif">{t("title")}</h2>

          <div className="flex flex-col flex-end">
            <span
              onClick={() => {
                reset()
                setValue("facets", [])
                setSelectedFacets([])
                dispatch(setJobsSlice({ search: "", location: "" }))
              }}
              tabIndex={0}
              className="text-dolBlue hover:underline cursor-pointer mt-auto mb-3 leading-4 text-center"
            >
              {t("clear_all")}
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-12 gap-6 -mt-3">
          {/* name = radius, label = "Within (miles)" */}
          {isJobsPage && (
            <Select
              disabled={!enableRadius}
              fieldsetTW={columnSpan}
              name="radius"
              label={t("within")}
              register={register}
              options={[10, 25, 50, 100]}
              optionMapper={"within_"}
              optionT={t}
            />
          )}
          {/* name = wage, label = "Wage (type)" */}
          {isJobsPage && (
            <Select
              fieldsetTW={columnSpan}
              name="wage"
              label={t("wage")}
              register={register}
              values={[
                "all",
                "Hour",
                "Week",
                "Bi-Weekly",
                "Month",
                "Piece Rate",
              ]}
              options={[
                "all",
                "hourly",
                "weekly",
                "bi-weekly",
                "monthly",
                "piecerate",
              ]}
              optionMapper={"wage_"}
              optionT={t}
            />
          )}

          {/* name = start_date, label = "Begin on or after Date" */}
          <DateInput
            fieldsetTW={columnSpan}
            name="start_date"
            label={t("begin")}
            control={control}
          />

          {/* name = job_type, label = "Job Type" */}
          <Select
            fieldsetTW={columnSpan}
            name="job_type"
            label={t("job_type")}
            register={register}
            values={["all", "H-2A", "H-2B"]}
            options={["all", "agricultural", "non-agricultural"]}
            optionMapper={"job_type_"}
            optionT={t}
          />

          {/* name = job_status, label = "Job Status" */}
          {isAdvancedSearchPage && (
            <Select
              fieldsetTW={columnSpan}
              name="job_status"
              label={t("job_status")}
              register={register}
              options={["all", "active", "inactive"]}
              optionMapper={"job_status_"}
              optionT={t}
            />
          )}
        </div>
      </div>
    </div>
  )
}

import { LabelActive } from "."
import { useTranslations } from "use-intl"
import { postPopularity } from "utils/fetchers/postPopularity"
import { DETAIL_VIEW } from "const/popularityEvents"

async function recordPopularity(case_number) {
  try {
    await postPopularity({
      case_number,
      event_type: DETAIL_VIEW,
    })
  } catch (e) {
    console.log(e)
  }
}
// Hide the menu by listening to the tab key
function handleOnKeyDown(e, onClick) {
  switch (e.key) {
    case " ":
    case "Enter":
      onClick()
      break
  }
}

export function JobPreviewCard({ which, onClick, current, preview, active }) {
  const t = useTranslations("Shared")
  return (
    <article
      tabIndex={0}
      onClick={async () => {
        onClick()
        await recordPopularity(preview.case_number)
      }}
      onKeyDown={async (e) => {
        handleOnKeyDown(e, onClick)
        recordPopularity(preview.case_number)
      }}
      className={
        current
          ? `cursor-pointer w-full flex flex-shrink-0 flex-col xxs:flex-row xxs:justify-between` +
            ` space-y-4 xxs:space-y-0 xxs:space-x-2 select-none transition-all motion-reduce:transition-none` +
            ` border-2 rounded-md border-primary-dark border-l-8 border-2 shadow rounded-md py-5 pb-5 pr-5 hover:shadow hover:bg-gray-50`
          : `justify-between cursor-pointer w-full flex flex-shrink-0 flex-col xxs:flex-row xxs:justify-between xxs:space-y-0 xxs:space-x-2` +
            ` select-none transition-all motion-reduce:transition-none border-2 p-5 rounded-md border-gray-200 bg-white`
      }
      style={current ? { paddingLeft: "0.88rem" } : { minWidth: "17rem" }}
    >
      <div className="flex flex-col justify-between">
        <div>
          <h3
            className={
              current
                ? "text-md mb-2 text-primary-dark font-bold font-serif leading-6"
                : "text-md mb-2 text-sjBlue font-bold font-serif leading-6"
            }
          >
            {preview.title}
          </h3>
          <div className="text-xs leading-4">
            <p className="text-gray-500">{preview.employerName}</p>
            <p className="text-gray-500">{preview.worksiteLocation}</p>
          </div>
        </div>
        <p className="text-xs mt-2">{preview.pay}</p>
      </div>
      <div className="flex flex-col justify-between items-end">
        <div className="flex flex-col text-xs text-right">
          <time dateTime={preview.beginDate} className="leading-4">
            {t("begin_date")} {preview.beginDate}
          </time>
          <time dateTime={preview.endDate} className="leading-4">
            {t("end_date")} {preview.endDate}
          </time>
        </div>
        {which === "archive" ? <LabelActive active={active} /> : null}
      </div>
    </article>
  )
}

import { useEffect, useState } from "react"
import { fetchJob } from "utils"
import { JobDetail } from "./JobDetail"

export function JobDetailCard({ case_number, classNameOverride, atlasRef }) {
  const [listing, setListing] = useState()

  useEffect(() => {
    async function getJob() {
      const l = await fetchJob({ case_number })
      setListing(l)
    }
    if (case_number) {
      getJob()
    }
  }, [case_number])

  return (
    <JobDetail
      classNameOverride={classNameOverride}
      listing={listing}
      card={true}
      atlasRef={atlasRef}
    />
  )
}

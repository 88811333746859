import { useTranslations } from "use-intl"

export function Counter({ current, total }) {
  const t = useTranslations("JobSearch")
  return current ? (
    <p className="text-xs font-bold">
      {/* Showing {current} of {total} results */}
      {t("Results.showing", { X: current, Y: total })}
    </p>
  ) : null
}

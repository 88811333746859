import { OG_URL } from "env"

export function generateMetaTags({
  title,
  description,
  imageTitle,
  imageDescription,
}) {
  const imageUrl = `${OG_URL}/api/og?title=${encodeURIComponent(
    imageTitle || title
  )}&description=${encodeURIComponent(imageDescription || description)}`
  return (
    <>
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://seasonaljobs.dol.gov" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://seasonaljobs.dol.gov" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
    </>
  )
}

import { useTranslations } from "use-intl"
import { JobPreviewCardsSkeleton } from "components/Skeletons"
import { JobPreviewCard } from "./JobPreviewCard"

function JobPreviewCardsContainer({
  data,
  current,
  setCurrentJob,
  which,
  onOpenModal,
  t,
}) {
  return (
    <>
      {data.map((listing) => (
        <JobPreviewCard
          which={which}
          onClick={() => {
            setCurrentJob(listing.case_number)
            onOpenModal()
          }}
          key={listing.case_number}
          t={t}
          current={current === listing.case_number}
          {...listing}
        />
      ))}
    </>
  )
}

export function JobPreviewCards({
  which,
  data,
  current,
  setCurrentJob,
  onOpenModal,
}) {
  const t = useTranslations("Shared")

  if (!data) return <JobPreviewCardsSkeleton />

  return (
    <JobPreviewCardsContainer
      data={data}
      current={current}
      setCurrentJob={setCurrentJob}
      which={which}
      onOpenModal={onOpenModal}
      t={t}
    />
  )
}

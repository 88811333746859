import { Fieldset } from "./Fieldset"

export function Select({
  disabled,
  defaultValue,
  fieldsetTW,
  overrideTW,
  labelTW,
  selectTW,
  name,
  label,
  register,
  options,
  values,
  optionMapper,
  optionT,
}) {
  return (
    <Fieldset tw={fieldsetTW} overrideTW={overrideTW}>
      <label htmlFor={name} className={`usa-label ${labelTW || ""}`}>
        {label}
      </label>
      <select
        id={name}
        autoComplete="off"
        className={`usa-select max-w-none ${
          disabled
            ? "cursor-not-allowed	bg-gray-100 text-gray-500"
            : selectTW || ""
        }`}
        aria-disabled={disabled}
        readOnly={disabled}
        disabled={disabled}
        defaultValue={defaultValue}
        {...register(name)}
      >
        {(values ? values : options).map((option, index) => (
          <option value={option} key={`${name}-${options[index]}`}>
            {optionMapper
              ? optionT(`${optionMapper}${options[index]}`)
              : options[index]}
          </option>
        ))}
      </select>
    </Fieldset>
  )
}

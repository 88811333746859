import React, { createRef, useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useTranslations } from "use-intl"
import { useRouter } from "next/router"
import { sanitize } from "utils/sanitize"
import Recaptcha from "components/Recaptcha"

export function Form({
  schema,
  defaultValues,
  children,
  onSubmit,
  loading,
  ...rest
}) {
  const router = useRouter()
  const s = useTranslations("Shared")

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: true,
    reValidateMode: "onChange",
  })

  const { handleSubmit } = methods
  const [code, setCode] = useState(0)

  async function onRecaptchaChange(code) {
    setCode(code)
  }

  return (
    <form
      onSubmit={handleSubmit((values) => {
        onSubmit(sanitize(values), code)
      })}
      {...rest}
    >
      {React.Children.map(children, (child) => {
        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register: methods.register,
                errors: methods.formState.errors,
                key: child.props.name,
              },
            })
          : child
      })}
      <Recaptcha onRecaptchaChange={onRecaptchaChange} />

      <button
        type="submit"
        disabled={!code}
        aria-disabled={!code}
        className="usa-button mt-6"
      >
        {loading ? s("loading") : s("submit")}
      </button>
    </form>
  )
}

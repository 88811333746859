import { useTranslations } from "use-intl"
import { richResolver } from "utils"

export function ZeroState() {
  const t = useTranslations("JobSearch.Zero")
  return (
    <section className="grid-container mb-6">
      <div className="border-2 border-gray-200 rounded-md p-10">
        <h2 className="text-2xl font-bold max-w-prose">
          {t.rich("title", richResolver)}
        </h2>
        <ul className="list-disc ml-4 mt-4">
          {t.rich("description", richResolver)}
        </ul>
      </div>
    </section>
  )
}

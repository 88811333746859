import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import { cloneElement, useEffect, useState } from "react"
import { useWindowSize } from "hooks"
import { CloseIcon } from "assets/CloseIcon"
import { useTranslations } from "use-intl"

export function ModalWrapper({
  open,
  onCloseModal,
  children,
  filterModal,
  actualModal,
  alertModal,
}) {
  const t = useTranslations("JobSearch")
  const [closedOnce, setClosedOnce] = useState(false)
  const size = useWindowSize()

  // close the modal programmtically if
  // user changes window size from mobile > tablet/desktop
  useEffect(() => {
    if (size.width > 768 && !closedOnce) {
      setClosedOnce(true)
      onCloseModal()
    }
  }, [closedOnce, onCloseModal, size])

  useEffect(() => {
    // reset closedOnce
    if (!actualModal) setClosedOnce(false)
  }, [open])

  if (!open && !actualModal) {
    return cloneElement(children, {
      classNameOverride: "hidden md:block md:w-full md:h-full",
    })
  }
  return (
    <Modal
      open={open}
      blockScroll={false}
      onClose={onCloseModal}
      center
      classNames={{
        modal: `w-screen m-0 p-0 ${filterModal ? "bg-gray-100" : ""} 
        ${alertModal ? "" : "h-full"}`,
        closeButton: actualModal
          ? "mr-1 opacity-70 hover:opacity-100"
          : "fixed mr-1 opacity-70 hover:opacity-100",
      }}
      closeIcon={<CloseIcon className="w-10 h-10" />}
    >
      {children}
      {filterModal ? (
        <div className="grid-container">
          <button
            className="usa-button usa-button--outline w-full"
            type="button"
            onClick={onCloseModal}
          >
            {t("Filters.apply")}
          </button>
        </div>
      ) : null}
    </Modal>
  )
}

import { useRouter } from "next/router"
import DatePicker, { registerLocale } from "react-datepicker"
import { Controller } from "react-hook-form"
import es from "date-fns/locale/es"
import { Fieldset } from "components/Form/Fieldset"

registerLocale("es", es)

const placeholderResolver = (locale) =>
  ({
    en: "mm/dd/yy",
    es: "mm/dd/aa",
  }[locale])

export function DateInput({ fieldsetTW, name, control, label, ...rest }) {
  const router = useRouter()
  return (
    <Fieldset tw={fieldsetTW}>
      <label htmlFor={name} className="usa-label">
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            id={name}
            locale={router.locale}
            className="usa-input max-w-none"
            placeholderText={placeholderResolver(router.locale)}
            autoComplete="off"
            onChange={onChange}
            selected={value}
            {...rest}
          />
        )}
      />
    </Fieldset>
  )
}

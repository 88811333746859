import { useTranslations } from "use-intl"

export function LabelActive({ active }) {
  const t = useTranslations("Shared")
  if (active) {
    return (
      <span className="w-min text-sm text-green-700 border-green-700 border font-bold rounded-sm px-2 mt-2">
        {t("active")}
      </span>
    )
  } else {
    return (
      <span className="w-min text-sm text-red-700 border-red-700 border font-bold rounded-sm px-2 mt-2">
        {t("inactive")}
      </span>
    )
  }
}

import { Alert } from "@trussworks/react-uswds"
import { useTranslations } from "use-intl"
import { richResolver } from "utils"

export function FetchError({ error }) {
  const t = useTranslations("Shared")
  console.error(error)
  return (
    <div className="grid-container mb-6">
      <Alert type="error">{t.rich("error_fetch_jobs", richResolver)}</Alert>
    </div>
  )
}
